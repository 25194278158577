import React, { useState } from 'react'

import { MailOutlined, LockOutlined } from '@ant-design/icons'
import { useOktaAuth } from '@okta/okta-react'
import { Row } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import { Field, reduxForm, change } from 'redux-form'

import {
  AuthForm,
  AuthHeaderBackground,
  AuthLogo,
  AuthHeaderTitle,
  AuthHeaderDescription,
  AuthBottom,
  AuthTitle,
  AuthBody
} from './AuthStyles'
import AuthImageTextInput from './components/AuthImageTextInput'
import CaptchaInput from './components/CaptchaInput'
import LogoImage from '../../assets/client_logo.png'
import OKTAIcon from '../../assets/icons/okta.svg'
import { AuthTokenKey } from '../../infra/config/LocalStorageKeys'
import { LoginAuth, ValidateEmail } from '../../infra/requests/AuthRequests'
import { GetAuthUser } from '../../infra/requests/BaseRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import { updatePermissions } from '../../redux/data/permissions/PermissionsActions'
import { userSave } from '../../redux/data/user/UserActions'
import GetBasicInformation from '../../redux/thunks/GetBasicInformation'
import AlertService from '../../shared/components/alert/AlertService'
import BaseButton from '../../shared/components/buttons/BaseButton'
import FooterCopyright from '../../shared/components/layout/footer/FooterCopyright'
import { StyledFlex, Margin } from '../../shared/styles/BasicStyles'

const formRules = FormValidator.make({
  email: 'required',
  captcha: 'required',
  password: 'required|min:6'
})

const Login = ({ router, handleSubmit, dispatch, translate }) => {
  const [loading, setLoading] = useState(false)
  const [validated, setValidated] = useState(false)

  const { oktaAuth } = useOktaAuth()

  const OktaLogin = async () => oktaAuth.signInWithRedirect()

  const onSubmit = async (values) => {
    setLoading(true)
    try {
      let tries = parseInt(localStorage.getItem('login_tries') || 0, 10)
      const expired = localStorage.getItem('login_expired')
      if (tries >= 5) {
        if (expired && moment(expired).isAfter(moment())) {
          setLoading(false)
          return AlertService.error('Too much login attempts', 'Please wait 15 min and try again')
        }
        tries = 0
      }
      if (validated) {
        const result = await LoginAuth(values)
        if (result.success) {
          localStorage.setItem(AuthTokenKey, result.data)
          const userData = await GetAuthUser()
          if (userData.success) {
            dispatch(userSave({
              fullName: userData?.data?.fullName,
              profileName: userData?.data?.profileName,
              profileId: userData?.data?.profileId,
              userId: userData?.data?.userId,
              ewpId: userData?.data?.ewpId
            }))
            dispatch(updatePermissions(userData?.data?.permissions))
          }
          dispatch(GetBasicInformation())
          return router.history.push('/')
        }
        localStorage.setItem('login_tries', tries + 1)
        localStorage.setItem('login_expired', moment().add(15, 'minutes').toString())
        return setLoading(false)
      }

      const result = await ValidateEmail({ Username: values.email })
      if (result.success) {
        setLoading(false)
        return setValidated(true)
      }
      localStorage.setItem('login_tries', tries + 1)
      localStorage.setItem('login_expired', moment().add(15, 'minutes').toString())

      dispatch(change('login', 'captcha', undefined))
      const bts = document.getElementsByClassName('rnc-button')
      const refresh = Array.from(bts).find((b) => b.ariaLabel = 'get new captcha')
      if (refresh) refresh.click()

      return setLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  const isDevEnv = window.location.hostname.indexOf('skypro-test') > -1 ||
    window.location.hostname.indexOf('localhost') > -1

  return (
    <AuthForm onSubmit={handleSubmit(onSubmit)}>
      <AuthLogo src={LogoImage} />
      <AuthHeaderBackground>
        <StyledFlex $justify='center' align='center'>
          <AuthHeaderTitle>
            <Translate id='WELCOME_PORTAL' />
          </AuthHeaderTitle>
          <AuthHeaderDescription>
            <Translate id='WELCOME_PORTAL_INTRO' />
          </AuthHeaderDescription>
        </StyledFlex>
      </AuthHeaderBackground>
      <AuthBody>
        <AuthTitle>
          <Translate id='SIGN_IN' />
        </AuthTitle>
        <Field
          name='email'
          type='text'
          image={<MailOutlined />}
          component={AuthImageTextInput}
          placeholder={translate('USERNAME')}
          disabled={validated}
        />
        <Margin size={15} />
        {!validated && (
          <Field
            name='captcha'
            component={CaptchaInput}
          />
        )}
        {validated && <Field
          name='password'
          type='password'
          image={<LockOutlined />}
          component={AuthImageTextInput}
          placeholder={translate('PASSWORD')}
        />}
        <Margin size={15} />
      </AuthBody>
      {!validated && (
        <Row justify='center' align='middle'>
          <BaseButton
            auto
            htmlType='submit'
            type='primary'
            loading={loading}
          >
            <Translate id='VALIDATE' />
          </BaseButton>
        </Row>
      )}
      {validated && (
        <Row justify='center' align='middle'>
          <BaseButton
            loading={loading}
            auto
            htmlType='submit'
            type='primary'
          >
            <Translate id='LOG_IN' />
          </BaseButton>
        </Row>
      )}
      <Margin size='15' />
      {validated && (
        <Row justify='center' align='middle'>
          <BaseButton
            auto
            type='secondary'
            onClick={() => router.history.push('/recover')}
          >
            <Translate id='RECOVER_PASSWORD' />
          </BaseButton>
        </Row>
      )}
      {isDevEnv && (
        <Row justify='center' align='middle' style={{ marginTop: '40px' }}>
          <BaseButton
            auto
            htmlType='button'
            type='secondary'
            onClick={OktaLogin}
          >
            Login with <img style={{ height: '30px' }} src={OKTAIcon} alt='okta' />
          </BaseButton>
        </Row>
      )}
      <AuthBottom>
        <FooterCopyright white />
      </AuthBottom>
    </AuthForm>
  )
}

Login.propTypes = {
  router: PropTypes.object.isRequired
}

const LoginPage = reduxForm({
  form: 'login',
  validate: formRules
})(Login)

export default withLocalize(
  connect()(LoginPage)
)
